import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import IconCard from '../atoms/card-icon';

// Form
import BookADemoForm from '../forms/book-a-demo-form';

// Components
import { HeroWrapper } from './hero';

// Logos
import BgLogo from '../logos/logo-bg.svg';

const propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.shape({
    content: PropTypes.string,
  }),
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
};

const defaultProps = {
  description: '',
  benefits: [],
};

const HeroBookForm = ({ heading, description, benefits, ...props }) => {
  return (
    <HeroWrapper className={cn('relative overflow-hidden', props.className)}>
      <img
        src={BgLogo}
        alt=""
        className="w-[200vw] md:w-[150vw] max-w-[1000px] lg:max-w-[3000px] h-auto absolute top-1/2 left-1/4 transform -translate-y-1/2 -rotate-[120deg] opacity-50 pointer-events-none touch-none mobile+tablet:hidden"
      />
      <div className="relative">
        <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 pt-8 lg:pt-24 pb-10 lg:pb-16">
          <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
            <div className="col-span-12 lg:col-start-3 lg:col-span-8 text-center">
              <h1 className="u-h3 text-white">{heading}</h1>
              {description && (
                <p className="text-white mt-4 lg:mt-8">{description.content}</p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8 mt-6 lg:mt-16">
            <div className="col-span-12 lg:col-start-2 lg:col-span-4 mobile+tablet:mb-10">
              {benefits?.length > 0 && (
                <div className="grid grid-cols-2 gap-4 lg:gap-8">
                  {benefits.map((benefit, key) => (
                    <div className="col-span-1" key={`icon-card-${key}`}>
                      <IconCard
                        title={benefit.title}
                        icon={benefit.icon}
                        description={benefit.description}
                        variant="white"
                        isCentered
                        size="small"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="col-span-12 lg:col-start-7 lg:col-span-5">
              <div className="bg-white rounded p-4 lg:p-8 text-grey-font-1">
                <BookADemoForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroWrapper>
  );
};

HeroBookForm.propTypes = propTypes;
HeroBookForm.defaultProps = defaultProps;

export default HeroBookForm;
