import { graphql, StaticQuery } from 'gatsby';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

// Component
import Layout from '../components/layout';
import Seo from '../components/seo';
import HeroBookForm from '../components/hero-book-form';
// import { PartnerNegative } from '../components/blocks/partner-block';

const HeroWithPartner = styled(HeroBookForm)`
  & > * {
    padding-bottom: ${(props) => props.offsetBottom + 40}px !important;

    @media (min-width: 1024px) {
      padding-bottom: ${(props) => props.offsetBottom + 64}px !important;
    }
  }
`;

const BookADemoPage = () => {
  // const [partnerOffset, setPartnerOffset] = useState(0);
  // const PartnerRef = useCallback((node) => {
  //   if (node !== null) {
  //     setPartnerOffset(node.clientHeight);
  //   }
  // }, []);

  return (
    <StaticQuery
      query={graphql`
        query BookADemoPageQuery {
          allContentfulBookADemoPage(
            filter: { publishable: { eq: "Publish" } }
          ) {
            nodes {
              title
              seoTitle
              seoDescription
              heading
              description {
                content: description
              }
              benefits {
                ... on ContentfulBenefit {
                  ...benefitFragment
                }
              }
              partnersTitle
              partners {
                ...partnerFragment
              }
            }
          }
        }
      `}
      render={(data) => {
        const params = data.allContentfulBookADemoPage.nodes[0];

        return (
          <Layout>
            <Seo
              title={params?.seoTitle || params.title}
              description={params?.seoDescription || ''}
            />
            <HeroWithPartner
              heading={params.heading}
              description={params.description}
              benefits={params.benefits}
              // offsetBottom={partnerOffset / 2}
            />
            {/* <PartnerNegative
              ref={PartnerRef}
              title={params.partnersTitle}
              partners={params.partners}
              offsetHeight={partnerOffset / 2}
              className="bg-grey-light"
            /> */}
          </Layout>
        );
      }}
    />
  );
};

export default BookADemoPage;
